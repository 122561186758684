import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Alert,
  Divider,
  Link,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import * as yup from "yup";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { posthogUtils, sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import { NotAuthLayout } from "../../shared/layouts";
import { signIn, userProfile } from "../../services/api";

import { useLocalStorage } from "../../shared/hooks";
import { userHomeURL } from "../../utils/helpers";
import { delTokenSSID, setTokenSSID } from "../../utils/axios";
import TagManager from "react-gtm-module";

import styles from "./styles.module.scss";
import { emailSchema, requiredStringSchema } from "utils/yupValidation";
import { toast } from "react-toastify";

import subject, { USER_LOGGED } from "../../utils/subjects";
import { setUserForErrorMonitor } from "errors/errorMonitor";
import { M_MODE } from "shared/constants/localStorage";
import { isAccessTokenExpired } from "utils";

import { oauthSignin } from "services/api/auth.service";
import { FcGoogle } from "react-icons/fc";
import { HStack } from "shared/components";

const formValidationSchema = yup.object().shape({
  username: emailSchema,
  password: requiredStringSchema(),
});

const initialErrorsState = {
  username: "",
  password: "",
};

const SignIn: FunctionComponent<{}> = (props): ReactComponentElement<any> => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const paramsMortgageMode = searchParams.get("m_mode");
  const navigate = useNavigate();
  const [form, setForm] = useState({ username: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(initialErrorsState);
  const [loginErrors, setLoginErrors] = useState(false);

  const [bToken] = useLocalStorage("access_token");
  const [name] = useLocalStorage("name");
  const [role] = useLocalStorage("role");
  const [email] = useLocalStorage("email");

  const validate = async () => {
    setErrors(initialErrorsState);
    setLoginErrors(false);
    try {
      const email = await formValidationSchema.validate(form, {
        abortEarly: false,
      });
      login();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.log({ error });
        const err = error.inner.reduce<{ [x: string]: string }>((errs, e) => {
          if (e?.path) {
            errs[e.path as string] = e.message;
          }
          return errs;
        }, {});
        console.log({ err });
        setErrors((prevErr) => ({ ...prevErr, ...err }));
      } else {
        console.log({ error }, "else error");
      }
    }
  };

  useEffect(() => {
    if (
      !name ||
      !role ||
      !email ||
      email === "null" ||
      isAccessTokenExpired()
    ) {
      localStorage.clear();
      delTokenSSID();
    }

    if (paramsMortgageMode) {
      localStorage.setItem(M_MODE, paramsMortgageMode);
    }
  }, []);

  useEffect(() => {
    if (bToken !== "") {
      navigate(userHomeURL(role));
      // window.location.href = userHomeURL(role);
    }
  }, [bToken, navigate, role]);

  useEffect(() => {
    if (location.search.indexOf("r") !== -1) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params?.r) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
          r: params.r,
        });
      }

      if (params?.error) {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado 😞, intenta iniciar sesión nuevamente y en caso de que sigas teniendo problemas, por favor ponte en contacto con nosotros. Error(siper)"
        );
      }
    } else {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, []);

  const login = () => {
    signIn(form)
      .then((response) => {
        const { role, access_token } = response.data.results;
        const payload: any = jwt_decode(response.data.results.access_token);

        let home =
          location.search.indexOf("ssid") !== -1
            ? "/client/mortgage/create?step=0"
            : "/client/home";

        if (role === "broker" || role === "bank") {
          home = "/broker/applications";
        }

        if (role === "realstate") {
          home = "/agent";
        }

        if (role === "influencer") {
          home = "/referrals";
        }

        subject.send(USER_LOGGED, response.data);
        setTokenSSID(access_token);

        localStorage.setItem("access_token", access_token);
        localStorage.setItem("role", role);
        localStorage.setItem("name", payload.name);
        localStorage.setItem("email", payload.email);
        localStorage.setItem("username", payload.preferred_username);
        localStorage.setItem("email_verified", payload.email_verified);

        TagManager.dataLayer({
          dataLayer: {
            event: "app_login_ok",
            error_message: "email",
          },
        });

        // if (role === "broker") {
        //   getProfileBroker();
        // }
        setUserForErrorMonitor({
          email: payload.email,
          username: payload.preferred_username,
        });

        posthogUtils.identifyUser({
          username: payload.preferred_username,
          name: payload.name,
          email: payload.email,
          role: role,
        });

        window.location.href = home;
      })
      .catch((e: any) => {
        console.log(e);
        setLoginErrors(true);
        TagManager.dataLayer({
          dataLayer: {
            event: "app_login_error",
            error_message: e.message,
          },
        });
      });
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
  };

  const redirectToSignUp = () => {
    if (location.search.indexOf("r") !== -1) {
      navigate({
        pathname: "/sign-up",
        search: location.search,
      });
    } else {
      navigate("/sign-up");
    }
  };

  return (
    <NotAuthLayout>
      <Container className={styles.LoginWrapper}>
        <Stack alignItems="center" marginTop={2} gap={3}>
          <Box width={{ md: "30%", sm: "100%" }}>
            <img src="/assets/img/sources/login.svg" alt="" width="100%" />
          </Box>
          <Stack
            component="form"
            width={{ md: "30%", sm: "100%" }}
            gap={2}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              validate();
            }}
          >
            {loginErrors && (
              <Box>
                <Alert severity="error">
                  Por favor valida tu usuario o contraseña
                </Alert>
                <Divider component="br" />
              </Box>
            )}

            <TextField
              label="Correo electrónico"
              placeholder="tucorreo@gmail.com"
              variant="outlined"
              error={!!errors.username}
              helperText={errors.username && errors.username}
              size="small"
              fullWidth
              name="username"
              onChange={onChangeHandler}
              type="email"
            />
            <TextField
              id="input-with-icon-textfield"
              label="Contraseña"
              fullWidth
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              name="password"
              onChange={onChangeHandler}
            />
            <Button variant="contained" fullWidth type="submit">
              Iniciar Sesión
            </Button>
            <Typography variant="caption" align="center">
              ¿Aún no tienes cuenta?{" "}
              <Link
                underline="hover"
                onClick={redirectToSignUp}
                sx={{ cursor: "pointer" }}
              >
                ¡Regístrate aquí!
              </Link>
            </Typography>
            <Divider variant="middle" />
            <GoogleAuthBtn />
            <Link
              className={styles.Recovery}
              underline="none"
              onClick={() => navigate("/reset-password")}
            >
              <Typography variant="caption" align="center">
                ¿Has olvidado tu contraseña?
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Container>
    </NotAuthLayout>
  );
};

export default SignIn;

function GoogleAuthBtn() {
  const handleGoogleBtn = () => {
    oauthSignin("google")
      .then((res) => {
        window.location.href = res.data.results.authorization_url;
      })
      .catch((e) => {
        console.error("oauth google", { e });
      });
  };
  return (
    <Button
      variant="outlined"
      fullWidth
      type="button"
      onClick={handleGoogleBtn}
    >
      <HStack gap={1} alignItems="center">
        <FcGoogle size={28} />
        Iniciar sesión con Google 
      </HStack>
    </Button>
  );
}
