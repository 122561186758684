import React, { FunctionComponent, ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { sendTagEvent } from "utils/analytics";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import TagManager from "react-gtm-module";
import Tooltip from "@mui/material/Tooltip";
import CustomDialog from "../CustomDialog";
import { useDialog, useLocalStorages} from "shared/hooks";
import { MORTGAGE_FORM_STATE } from "shared/constants/localStorage";

export enum AvailableDirectAccess {
  NEW_APPLICATIONS,
  NEW_MORTGAGE,
  DOCUMENTS,
  IMPRO_MORTGAGE,
  ACTIVE_MORTGAGE,
  COMPLETE_MORTGAGE,
  MORTGAGES,
  DOCUMENTS_CERTIFICATE,
  CERTIFICATE,
  UPLOAD_DOCUMENTS,
  RAISE_CAPITAL,
  REFERAL_REWARD,
  AUTOPROMOTOR,
  INVESTOR
}

export interface IDirectAccessProps {
  only: AvailableDirectAccess[];
  onClick: (action: AvailableDirectAccess) => void;
  fullWidth?: boolean;
}
const DirectAccess: FunctionComponent<IDirectAccessProps> = ({
  only,
  onClick,
  fullWidth,
}): ReactElement<any> => {
  const navigate = useNavigate();
  const { isShowing: dialogOpen, toggle: toggleDialog } = useDialog();

  return (
    <Box className={styles.HeroButtons}>
      {only.indexOf(AvailableDirectAccess.NEW_APPLICATIONS) !== -1 &&
        fullWidth && (
          <>
            <Tooltip title="Si buscas una nueva hipoteca, mejorar las condiciones de tu hipoteca actual o ampliar el capital hipotecado, esta es tu opción">
              <Box
                className={styles.HeroButton}
                onClick={() => {
                  const mortgageFormValue =
                    window.localStorage.getItem(MORTGAGE_FORM_STATE);
                  if (mortgageFormValue) {
                    toggleDialog();
                  } else {
                    onClick(AvailableDirectAccess.NEW_APPLICATIONS);
                  }
                }}
              >
                <img src="/assets/img/sources/applications.svg" alt="" />
                <span className={styles.CustomTitle}>
                  Crear nueva solicitud
                </span>
              </Box>
            </Tooltip>
            <CustomDialog
              open={dialogOpen}
              title="¿Desea retomar el formulario donde lo dejó la última vez?"
              btnList={[
                {
                  onClick: () => {
                    window.localStorage.removeItem(MORTGAGE_FORM_STATE);
                    onClick(AvailableDirectAccess.NEW_APPLICATIONS);
                    toggleDialog();
                  },
                  title: "Cancelar",
                },
                {
                  onClick: () => {
                    toggleDialog();
                    navigate("/client/mortgage/create");
                  },
                  title: "Reanudar",
                },
              ]}
            >
              <Typography>
                📃 Parece que tienes un formulario pendiente.
              </Typography>
              <Typography>
                Puedes cancelar y empezar desde cero ó reanudar donde lo
                dejaste.
              </Typography>
            </CustomDialog>
          </>
        )}

      {only.indexOf(AvailableDirectAccess.NEW_APPLICATIONS) !== -1 &&
        !fullWidth && (
          <Box
            className={styles.HeroButton}
            onClick={() => onClick(AvailableDirectAccess.NEW_APPLICATIONS)}
          >
            <img src="/assets/img/sources/applications.svg" alt="" />
            <span>Crear nueva solicitud</span>

            {!fullWidth && (
              <Typography variant="caption" className={styles.CustomCaption}>
                ¿Buscas tu primera hipoteca, mejorar las condiciones o
                ampliarla? entonces esta es tu opción.
              </Typography>
            )}
          </Box>
        )}

      {only.indexOf(AvailableDirectAccess.NEW_MORTGAGE) !== -1 && (
        <SolicitorTypeCard
          title="Nueva hipoteca"
          desc="Si ya conoces la casa que quieres comprar, solicita una hipoteca."
          imgSrc="/assets/img/sources/house-search.svg"
          onClick={() => {
            onClick(AvailableDirectAccess.NEW_MORTGAGE);
            sendTagEvent({
              event: "app_mis_solicitudes_nueva_hipoteca",
              method: "email",
            });
          }}
        />
      )}

      {only.indexOf(AvailableDirectAccess.IMPRO_MORTGAGE) !== -1 && (
        <SolicitorTypeCard
          title="Mejora de hipoteca"
          desc="Mejora las condiciones de tu hipoteca actual."
          imgSrc="/assets/img/sources/settings.svg"
          onClick={() => {
            onClick(AvailableDirectAccess.IMPRO_MORTGAGE);
            sendTagEvent({
              event: "app_mis_solicitudes_mejorar_hipoteca",
              method: "email",
            });
          }}
        />
      )}

      {only.indexOf(AvailableDirectAccess.AUTOPROMOTOR) !== -1 && (
        <SolicitorTypeCard
          title="Autopromotor"
          desc="Construye tu propia casa."
          imgSrc="/assets/img/sources/under_construction.svg"
          onClick={() => {
            onClick(AvailableDirectAccess.AUTOPROMOTOR);
            sendTagEvent({
              event: "app_mis_solicitudes_autopromotor",
              method: "email",
            });
          }}
        />
      )}

      {only.indexOf(AvailableDirectAccess.DOCUMENTS) !== -1 && !fullWidth && (
        <Box
          className={styles.HeroButton}
          onClick={() => {
            onClick(AvailableDirectAccess.DOCUMENTS);
          }}
        >
          <img src="/assets/img/sources/documents.svg" alt="" />

          <span>Mis documentos</span>
          <Typography variant="caption" className={styles.CustomCaption}>
            Gestiona los documentos necesarios para tu hipoteca.
          </Typography>
        </Box>
      )}

      {only.indexOf(AvailableDirectAccess.DOCUMENTS) !== -1 && fullWidth && (
        <Tooltip title="Carga y gestiona la documentación para la tramitación de tu hipoteca">
          <Box
            className={styles.HeroButton}
            onClick={() => {
              onClick(AvailableDirectAccess.DOCUMENTS);
            }}
          >
            <img src="/assets/img/sources/documents.svg" alt="" />

            <span className={styles.CustomTitle}>Mis documentos</span>
          </Box>
        </Tooltip>
      )}

      {only.indexOf(AvailableDirectAccess.UPLOAD_DOCUMENTS) !== -1 &&
        !fullWidth && (
          <Box
            className={styles.HeroButton}
            onClick={() => {
              onClick(AvailableDirectAccess.UPLOAD_DOCUMENTS);
              sendTagEvent({
                event: "app_mis_documentos_subir_documentos",
              });
            }}
          >
            <img src="/assets/img/sources/documents.svg" alt="" />

            <span className={styles.CustomTitle}>Subir documentos</span>
            <Typography variant="caption" className={styles.CustomCaption}>
              Puedes subir la documentación que necesitan bancos y asesores
              manualmente, si tienes estos documentos guardados en tu
              dispositivo.
            </Typography>
          </Box>
        )}

      {only.indexOf(AvailableDirectAccess.CERTIFICATE) !== -1 && (
        <Box
          className={styles.HeroButton}
          onClick={() => onClick(AvailableDirectAccess.CERTIFICATE)}
        >
          <img src="/assets/img/sources/secure.svg" alt="" />

          <span className={styles.CustomTitle}>Subir certificado digital</span>
          <Typography variant="caption" className={styles.CustomCaption}>
            Con tu certificado, autorizas legalmente a Colibid para descargar la
            documentación necesaria en tu nombre. Toda la información es tratada
            con confidencialidad.
          </Typography>
        </Box>
      )}

      {only.indexOf(AvailableDirectAccess.ACTIVE_MORTGAGE) !== -1 && (
        <Box
          className={styles.HeroButton}
          onClick={() => onClick(AvailableDirectAccess.ACTIVE_MORTGAGE)}
        >
          <img src="/assets/img/sources/bidding.png" alt="" />
          <span>Subastas activas</span>
        </Box>
      )}

      {only.indexOf(AvailableDirectAccess.COMPLETE_MORTGAGE) !== -1 && (
        <Box
          className={styles.HeroButton}
          onClick={() => onClick(AvailableDirectAccess.COMPLETE_MORTGAGE)}
        >
          <img src="/assets/img/sources/auction.svg" alt="" />

          <span>Subastas completadas</span>
        </Box>
      )}

      {only.indexOf(AvailableDirectAccess.MORTGAGES) !== -1 && !fullWidth && (
        <Box
          className={styles.HeroButton}
          onClick={() => onClick(AvailableDirectAccess.MORTGAGES)}
        >
          <img src="/assets/img/sources/undraw_attached_file.svg" alt="" />

          <span>Mis solicitudes</span>
          <Typography variant="caption" className={styles.CustomCaption}>
            Dale seguimiento al estado de tus solicitudes creadas, y a las
            ofertas que has recibido.
          </Typography>
        </Box>
      )}

      {only.indexOf(AvailableDirectAccess.MORTGAGES) !== -1 && fullWidth && (
        <Tooltip title="Dale seguimiento al estado de tus solicitudes">
          <Box
            className={styles.HeroButton}
            onClick={() => onClick(AvailableDirectAccess.MORTGAGES)}
          >
            <img src="/assets/img/sources/undraw_attached_file.svg" alt="" />

            <span className={styles.CustomTitle}>Mis solicitudes</span>
          </Box>
        </Tooltip>
      )}

      {only.indexOf(AvailableDirectAccess.DOCUMENTS_CERTIFICATE) !== -1 && (
        <Box
          className={styles.HeroButton}
          onClick={() => {
            onClick(AvailableDirectAccess.DOCUMENTS_CERTIFICATE);

            sendTagEvent({
              event: "app_mis_documentos_subir_certificado_digital",
            });
          }}
        >
          <img src="/assets/img/sources/certificate.svg" alt="" />

          <span>Subir certiticado digital </span>
        </Box>
      )}
      {only.indexOf(AvailableDirectAccess.RAISE_CAPITAL) !== -1 && (
        <SolicitorTypeCard
          title="Ampliación de capital"
          desc="Encuentra más financiación ampliando tu hipoteca."
          imgSrc="/assets/img/sources/raise_capital.svg"
          onClick={() => {
            onClick(AvailableDirectAccess.RAISE_CAPITAL);
            sendTagEvent({
              event: "app_mis_solicitudes_ampliacion_capital",
              method: "email",
            });
          }}
        />
      )}

      {only.indexOf(AvailableDirectAccess.REFERAL_REWARD) !== -1 &&
        !fullWidth && (
          <Box
            className={styles.HeroButton}
            onClick={() => {
              onClick(AvailableDirectAccess.REFERAL_REWARD);

              sendTagEvent({
                event: "app_mis_solicitudes_ampliacion_capital",
                method: "email",
              });
            }}
          >
            <img src="/assets/img/sources/referal_reward_home.svg" alt="" />

            <span className={styles.CustomTitle}>Consigue 100€</span>
            <Typography variant="caption" className={styles.CustomCaption}>
              Gana dinero ayudando a tus amigos.
            </Typography>
          </Box>
        )}

      {only.indexOf(AvailableDirectAccess.REFERAL_REWARD) !== -1 &&
        fullWidth && (
          <Tooltip title=" Gana dinero ayudando a tus amigos">
            <Box
              className={styles.HeroButton}
              onClick={() => onClick(AvailableDirectAccess.REFERAL_REWARD)}
            >
              <img src="/assets/img/sources/referal_reward_home.svg" alt="" />

              <span className={styles.CustomTitle}>Consigue 100€</span>
            </Box>
          </Tooltip>
        )}
    </Box>
  );
};

export default DirectAccess;

const SolicitorTypeCard = ({
  title,
  desc,
  imgSrc,
  onClick,
}: {
  title: string;
  desc: string;
  imgSrc: string;
  onClick: () => void;
}) => {
  return (
    <Box className={styles.HeroButton} onClick={onClick}>
      <img src={imgSrc} alt={`${title} image`} />
      <div>
        <span className={styles.CustomTitle}>{title}</span>
        <Typography variant="caption" className={styles.CustomCaption}>
          {desc}
        </Typography>
      </div>
    </Box>
  );
};