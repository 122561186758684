import {Box, Button, Paper, Skeleton, Stack, Typography} from "@mui/material";
import ReactGA from "react-ga";
import {navItems} from "shared/constants/navigations";
import {useProfile} from "shared/hooks";
import {PageTitle} from "../../..";
import {AvailableDirectAccess} from "../../../DirectAccess";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import {getGA_actionLabel, getMortgageModeValue} from "../../utils";
import {ClientProfile} from "shared/types/Profile";
import Alert from '@mui/material/Alert';
import styles from "./styles.module.scss";

interface NewMortgage {
    title: string;
    description: string;
    imgUrl: string;
    mode: number;
}

const title = navItems.newApplication;
const newApplicationOptions: NewMortgage[] = [
    {
        title: "Nueva hipoteca",
        description: "¿Ya tienes escogida tu vivienda? ¡Solicita una hipoteca!",
        imgUrl:
            "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/undraw_House_searching_re_stk8.png",
        mode: AvailableDirectAccess.NEW_MORTGAGE,
    },
    {
        title: "Mejorar mi hipoteca",
        description:
            "Mejora las condiciones de tu hipoteca actual. ¡Puedes hacerlo cuantas veces desees!",
        imgUrl:
            "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/undraw_Interior_design_re_7mvn.png",
        mode: AvailableDirectAccess.IMPRO_MORTGAGE,
    },
    // {
    //   title: "Ampliar mi hipoteca",
    //   description: "Encuentra más financiación ampliando tu hipoteca",
    //   imgUrl:
    //     "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/undraw_Buy_house_re_8xq7.png",
    //   mode: AvailableDirectAccess.RAISE_CAPITAL,
    // },
    // {
    //   title: "Autopromoción",
    //   description: "¡Contruye tu propia casa!",
    //   imgUrl:
    //     "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/undraw_under_construction_46pa.png",
    //   mode: AvailableDirectAccess.AUTOPROMOTOR,
    // },
    {
        title: "Inversión Inmobiliaria",
        description: "Aumenta tu patrimonio con más propiedades para alquilar o vender. ¡Empieza hoy!",
        imgUrl:
            "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/undraw_inversion_inmobiliaria.png",
        mode: AvailableDirectAccess.INVESTOR,
    }
];

function StepZero() {
    const {onStepClick, mortgageFormData} = useMortgageFormContext();
    const {profile, isFetching} = useProfile<ClientProfile>(false);

    if (isFetching) return <StepZeroSkeleton/>;

    const handleClick = (action: AvailableDirectAccess) => {

        //NOTION TASK: CHT-336
        //https://www.notion.so/Add-new-button-to-the-Mortgage-options-196c98d49670805d9ca4c2f5cec53ded?pvs=4
        if (action === AvailableDirectAccess.INVESTOR) {
            // Redirect the client to the Typeform URL
            // window.location.href = "https://form.typeform.com/to/xwFbid1d"; //to just redirect
            window.open("https://form.typeform.com/to/xwFbid1d", "_blank"); //to open a new tab
            return; // Exit the function after redirecting
        }

        const mode = getMortgageModeValue(action);
        const newForm = {
            ...mortgageFormData,
            mortgage: {mode},
        };
        ReactGA.event({
            category: "Mortgage Form",
            action: getGA_actionLabel(action),
            value: 1,
        });
        onStepClick()(newForm); // boobs
    };

    return (
        <Stack id="stepzero" gap={2} paddingX={4} maxWidth="fit-content">
            <Box paddingLeft={3}>
                {profile ? (
                    <PageTitle.WithIcon label={title.label} iconUrl={title.iconUrl}/>
                ) : (
                    <PageTitle.WithIcon label={"Registrarse"} iconUrl={title.iconUrl}/>
                )}
            </Box>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                gap={5}
                width="100vw"
                maxWidth="100vw"
                padding={2}
                sx={{boxSizing: "border-box", "& > *": {flexBasis: 280}}}
            >
                {newApplicationOptions.map((e) => {
                    return (
                        <Button
                            key={e.title}
                            component={Paper}
                            onClick={() => {
                                handleClick(e.mode);
                            }}
                        >
                            <Stack
                                gap={1}
                                alignItems="center"
                                justifyContent="center"
                                padding={2}
                            >
                                {/*<Typography variant="h6">{e.title}</Typography>*/}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        // fontSize: "1rem", // Adjust font size
                                        whiteSpace: "nowrap", // Prevent wrapping
                                        textAlign: "center", // Ensure it is centered
                                    }}
                                >
                                    {e.title}
                                </Typography>

                                <Typography
                                    color="black"
                                    variant="body1"
                                    textAlign="center"
                                    sx={{textTransform: "none"}}
                                >
                                    {e.description}
                                </Typography>
                                <img
                                    alt="Colibid products"
                                    src={e.imgUrl}
                                    height={150}
                                    width="100%"
                                    style={{
                                        mixBlendMode: "multiply",
                                    }}
                                />
                            </Stack>
                        </Button>
                    );
                })}
            </Stack>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                gap={5}
                width="100vw"
                maxWidth="100vw"
                padding={2}
            >
                <Alert severity="info">Si estás buscando otro tipo de operación, pincha <a className={styles.links}
                                                                                           href="https://colibid.zendesk.com/hc/es/sections/13369472968349-I-FAQs-Colibid"
                                                                                           target="_blank"
                                                                                           rel="noopener noreferrer">aquí</a></Alert>
            </Stack>
        </Stack>
    );
}

function StepZeroSkeleton() {
    return (
        <Stack gap={2}>
            <PageTitle.WithIcon label={title.label} iconUrl={title.iconUrl}/>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                gap={2}
                maxWidth="100vw"
                padding={2}
                sx={{boxSizing: "border-box", "& > *": {flexBasis: 280}}}
            >
                {newApplicationOptions.map((e) => {
                    return (
                        <Paper key={e.title}>
                            <Stack alignItems="center" justifyContent="center" padding={2}>
                                <Skeleton variant="text" height={40} width={100}/>
                                <Skeleton variant="text" width={200}/>
                                <Skeleton variant="text" width={180}/>
                                <Skeleton height={150} width={200}/>
                            </Stack>
                        </Paper>
                    );
                })}
            </Stack>
        </Stack>
    );
}

export default StepZero;
